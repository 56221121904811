<template>
    <!-- <div class="outer" ref="scroll"> -->
    <!-- <div class="pulldown-wrapper">
            <div v-show="beforePullDown">
                <span>下拉刷新</span>
            </div>
            <div v-show="!beforePullDown">
                <div v-show="isPullingDown">
                    <span>加载中...</span>
                </div>
                <div v-show="!isPullingDown">
                    <span>刷新成功</span>
                </div>
            </div>
        </div> -->
    <div class="container">
        <div class="top-box">
            <img class="background" src="/img/main/background.png" alt="">
			<div class="top_tit">
				<div>
					<div class="top">完善机构信息</div>
					<div class="bottom">
						<div class="phone">温馨提示：需要您完善机构信息。</div>
					</div>
				</div>
				<div @click="logout" class="logut_btn">退出</div>
			</div>
        </div>
        <div class="refine-box">
            <div class="refine-box__title">
                <div><span style="color: #F7203E">*</span>机构所在省市区</div>
            </div>
			<div class="refine-box__city" @click="select_area">{{area_str?area_str:'省/市/县'}}</div>
            <div class="refine-box__title">
                <div><span style="color: #F7203E">*</span>机构详细地址</div>
            </div>
            <el-input class="refine-box__input" v-model="address" placeholder="请输入机构详细地址"></el-input>
			<div class="refine-box__title">
                <div><span style="color: #F7203E">*</span>机构成立时间</div>
            </div> 
            <el-select class="refine-box__select" v-model="year" placeholder="请选择">
                <el-option
                  v-for="item in year_list"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            <div class="refine-box__title">
                <div><span style="color: #F7203E">*</span>机构举办性质</div>
            </div>
            <el-select class="refine-box__select" v-model="type_name" placeholder="请选择">
                <el-option
                  v-for="(value,key) in type_arr"
                  :key="key"
                  :value="value">
                </el-option>
              </el-select>
            <div class="refine-box__title">
                <div><span style="color: #F7203E">*</span> 举办者身份</div>
            </div>
			<el-select class="refine-box__select" v-model="user_type_name" placeholder="请选择">
			    <el-option
			      v-for="(value,key) in user_type_arr"
			      :key="key"
			      :value="value">
			    </el-option>
			  </el-select>
			  
			  
			  
            <div class="refine-box__title">
                <div><span style="color: #F7203E">*</span>最大可收托婴幼儿总数</div>
            </div>
            <el-select class="refine-box__select" v-model="child_num_name" placeholder="请选择">
                <el-option
                  v-for="(value,key) in child_num_arr"
                  :key="key"
                  :value="value">
                </el-option>
              </el-select>
            <div class="refine-box__title">
                <div><span style="color: #F7203E">*</span>开设的班型</div>
            </div>
			
            <el-checkbox-group v-model="class_type_name" class="refine-box__class_type_arr">
				<el-checkbox v-for="(value,key) in class_type_arr" :label="value"></el-checkbox>
			  </el-checkbox-group>
			  <div class="refine-box__title">
				  <div style="margin-right:1rem;"><span style="color: #F7203E">*</span>托育幼儿的最小月龄</div>
				<el-input-number v-model="min_age" size="small" :min="1"  label="最小月龄"></el-input-number>
			  </div>
            <div class="refine-box__title">
                <div><span style="color: #F7203E">*</span>请上传托育机构周边环境视频</div>
            </div>
            <div class="refine-box__subtitle">可上传1个视频，支持MP4、MOV格式</div>
			<div v-if="environment_url" class="video_item">
			  <div class="video-wrap video_bg">
				<video :src="'https://servicetuoyusaas.obs.cn-east-3.myhuaweicloud.com/'+environment_url" style="width: 100%;"></video>
				<img class="upload-icon" src="@/assets/upload/play_icon.png" alt=""  :video_url="'https://servicetuoyusaas.obs.cn-east-3.myhuaweicloud.com/'+environment_url" @click="swiperShow">
			  </div>
			  <img class="upload-del" src="@/assets/upload/del_icon.png"  @click="delVideo('environment_url')" alt="" />
			</div>
            <el-upload v-if="!environment_url"  action="#" list-type="picture-card"  multiple :show-file-list="false" accept="video/*"
                :auto-upload="false" :on-change="selectVideo1">
                <i class="el-icon-plus"></i>
            </el-upload>
            <div class="refine-box__title">
                <div><span style="color: #F7203E">*</span>请上传托育机构整体外观视频（如园门、建筑物等）</div>
            </div>
            <div class="refine-box__subtitle">可上传1个视频，支持MP4、MOV格式</div>
            <div v-if="outside_url" class="video_item">
              <div class="video-wrap video_bg">
            	<video :src="'https://servicetuoyusaas.obs.cn-east-3.myhuaweicloud.com/'+outside_url" style="width: 100%;"></video>
            	<img class="upload-icon" src="@/assets/upload/play_icon.png" alt="" 
				 :video_url="'https://servicetuoyusaas.obs.cn-east-3.myhuaweicloud.com/'+outside_url" @click="swiperShow">
              </div>
              <img class="upload-del" src="@/assets/upload/del_icon.png"  @click="delVideo('outside_url')" alt="" />
            </div>
            <el-upload v-if="!outside_url"  action="#" list-type="picture-card"  multiple :show-file-list="false" accept="video/*"
                :auto-upload="false" :on-change="selectVideo2">
                <i class="el-icon-plus"></i>
            </el-upload>
            <div class="refine-box__title">
                <div><span style="color: #F7203E">*</span>请上传托育机构内部环境视频</div>
            </div>
            <div class="refine-box__subtitle">可上传1个视频，支持MP4、MOV格式</div>
            <div v-if="act_url" class="video_item">
              <div class="video-wrap video_bg">
            	<video :src="'https://servicetuoyusaas.obs.cn-east-3.myhuaweicloud.com/'+act_url" style="width: 100%;"></video>
            	<img class="upload-icon" src="@/assets/upload/play_icon.png" alt="" 
            	 :video_url="'https://servicetuoyusaas.obs.cn-east-3.myhuaweicloud.com/'+act_url" @click="swiperShow">
              </div>
              <img class="upload-del" src="@/assets/upload/del_icon.png"  @click="delVideo('act_url')" alt="" />
            </div>
            <el-upload v-if="!act_url"  action="#" list-type="picture-card"  multiple :show-file-list="false" accept="video/*"
                :auto-upload="false" :on-change="selectVideo3">
                <i class="el-icon-plus"></i>
            </el-upload>
            <div class="refine-box__title">
                <div><span style="color: #F7203E">*</span>请上传托育机构某个班级的布局和环境 (正在进行师幼互动) 的视频</div>
            </div>
            <div class="refine-box__subtitle">可上传1个视频，支持MP4、MOV格式</div>
            <div v-if="inhouse_url" class="video_item">
              <div class="video-wrap video_bg">
            	<video :src="'https://servicetuoyusaas.obs.cn-east-3.myhuaweicloud.com/'+inhouse_url" style="width: 100%;"></video>
            	<img class="upload-icon" src="@/assets/upload/play_icon.png" alt="" 
            	 :video_url="'https://servicetuoyusaas.obs.cn-east-3.myhuaweicloud.com/'+inhouse_url" @click="swiperShow">
              </div>
              <img class="upload-del" src="@/assets/upload/del_icon.png"  @click="delVideo('inhouse_url')" alt="" />
            </div>
            <el-upload v-if="!inhouse_url"  action="#" list-type="picture-card"  multiple :show-file-list="false" accept="video/*"
                :auto-upload="false" :on-change="selectVideo4">
                <i class="el-icon-plus"></i>
            </el-upload>
        </div>
		
        <div class="forget-btn-box" style="flex-direction: column">
            <el-button class="login-btn" @click="confirm" type="primary" v-throttle>确定
            </el-button>
            <div class="footer">北京京学科技发展集团有限公司提供技术支持</div>
        </div>
		<div class="mask" v-show="mask"></div>
		
		<div class="select_area_box" v-if="show_select_area_box">
			<div class="tit">请选择{{area_leave}}</div>
			<div class="area_list">
				<div v-for="item in area_list"  :code="item.code" :text="item.text" @click="choose_area">{{item.text}}</div>
			</div>
		</div>
		<el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="100%">
		  <swiper :options="swiperOption" :auto-update="true" :auto-destroy="true" ref="Swiper">
		    <swiper-slide v-for="(item,index) of swiperData" :key="index">
		      <div class="inner">
		        <div class="inner-box">
		          <img v-if="swiperType == 'img'" :src="item.url" alt="">
		          <video controls autoplay v-else :src="item.url"></video>
		        </div>
		      </div>
		    </swiper-slide>
		  </swiper>
		</el-dialog>
    </div>
	
</template>
<script>
import api from '../../utils/api';
import obs from "@/utils/obs.js";
import {
  Swiper,
  SwiperSlide,
  directive
} from "vue-awesome-swiper"
import "swiper/css/swiper.css";
export default {
	name: "Refine",
    data() {
        return {
			swiperOption: {
			  // 设定初始化时slide的索引
			  initialSlide: 0,
			  direction: "horizontal",
			  on: {
			    slideChange: function () {
			      this.activeName = this.activeIndex.toString()
			    }
			  }
			},
			dialogTitle: "",
			swiperType: '',
			dialogImageUrl: "",
			dialogVisible: false,
			disabled: false,
			address:'',
			swiperData: '',
			inspect_id:'',
			area_leave:'省',
			show_select_area_box:false,
			area_str:'',
			area_list:[],
			min_age:0,
			min_age_list:[],
			year:new Date().getFullYear(),
			year_list:[],
			type_name:1,
			type_arr:[{value:1,lable:1},{value:2,lable:2}],
			user_type_name:'',
			user_type_arr:[],
			child_num_name:'',
			child_num_arr:[],
			class_type_arr:[],
			class_type_name:[],
			environment_url:'',
			act_url:'',
			inhouse_url:'',
			outside_url:'',
			addInp :false,
			mask:false,
			video_field_name:'',
			pwd2:''
        }
    },
    watch: {

    },
    components: {
      Swiper,
      SwiperSlide
    },
    mounted() {
		console.log('初始化');
        this.name = localStorage.getItem('name')
        this.username = localStorage.getItem('username')
        this.role = localStorage.getItem('role')
		this.organization_id = localStorage.getItem('organization_id') 
		this.$api({
		    method: 'post',
		    url: 'api/organ/organdetial',
			data:{id:this.organization_id},
		}).then(res=>{
			let data=res.data;
			this.pro_text=data.province_name;
			this.city_text=data.city_name;
			this.area_text=data.area_name;
			this.year=data.start_year;
			this.min_age=data.min_age;
			this.address=data.address;
			this.child_num_arr=data.child_num_arr;
			this.child_num_name=data.child_num_name!='未知'?data.child_num_name:'';
			this.user_type_arr=data.user_type_arr;
			this.user_type_name=data.user_type_name!='未知'?data.user_type_name:'';
			this.type_arr=data.type_arr;
			this.type_name=data.type_name!='未知'?data.type_name:'';
			this.class_type_arr=data.class_type_arr;
			this.class_type_name=data.class_type_name.split(',');
			this.environment_url=data.environment_url;
			this.outside_url=data.outside_url;
			this.act_url=data.act_url;
			this.inhouse_url=data.inhouse_url;
			this.pro_code=data.province_code;
			this.city_code=data.city_code;
			this.area_code=data.area_code;
			console.log(this.child_num_arr);
			this.area_str=this.pro_text+'/'+this.city_text+'/'+this.area_text;
		});
		for(var i=this.year;i>this.year-50;i--){
			this.year_list.push({lable:i,value:i});
		}
		for(var i=1;i<48;i++){
			this.min_age_list.push({lable:i,value:i});
		}
		
    },
	
    methods: {
		logout:function(){
			localStorage.removeItem("token");
			this.$router.push({path: '/user/login',replace:true})
		},
		confirm:function(){
		let param={};
		param['id']=this.organization_id;
		param['province_code']=this.pro_code;
		param['city_code']=this.city_code;
		param['area_code']=this.area_code;
		param['start_year']=this.year;
		for(var key in this.type_arr){
			if(this.type_arr[key]==this.type_name){
				param['type']=key;
				break;
			}
		}
		for(var key in this.user_type_arr){
			if(this.user_type_arr[key]==this.user_type_name){
				param['user_type']=key;
				break;
			}
		}
		param['child_num']=0;
		for(var key in this.child_num_arr){
			if(this.child_num_arr[key]==this.child_num_name){
				param['child_num']=key;
				break;
			}
		}
		param['class_type']=[];
		for(var key in this.class_type_arr){
			if(this.class_type_name.indexOf(this.class_type_arr[key])>-1){
				param['class_type'].push(key);
				
			}
		}
		param['class_type']=param['class_type'].join(',');
		param['environment_url']=this.environment_url;
		param['start_year']=this.year;
		param['outside_url']=this.outside_url;
		param['act_url']=this.act_url;
		param['inhouse_url']=this.inhouse_url;
		param['min_age']=this.min_age;
		param['address']=this.address;
		//return console.log(param);
		if(!param['province_code']||!param['city_code']||!param['area_code']){
			return this.$message({
			  message: '请选择地区',
			  type: "warning",
			});
		}
		for(var key in param){
			if(!param[key]){
				return this.$message({
				  message: '请填写完整信息',
				  type: "warning",
				});
			}
		}
		
		this.$api({
		    method: 'post',
		    url: 'api/organ/ownsave',
			data:param
		}).then(res=>{
			this.$message.success(res.msg);
			localStorage.setItem("is_organ_check", 1);
			var that=this;
			setTimeout(function(){
				that.$router.push({ path: "/" });
			},1000)
			
		});	
		},
		delVideo(filed_name) {
		  this[filed_name]='';
		},
		swiperShow:function(e){
			
		  let video_url=e.target.getAttribute('video_url');
		  console.log(video_url);
		  this.swiperType = 'video';
		  this.swiperOption.initialSlide = 0;
		  this.swiperData = [{url:video_url}];
		  this.dialogVisible = true; 
		  
		},
		//在methodes中定义方法
		// 点击弹出三级联动
		select_area:function(){
			this.show_select_area_box=true;
			this.pro_text='省';
			this.city_text='市';
			this.area_text='区';
			this.set_pro_data();
			console.log('开始选择地区');
		},
		set_pro_data:function(){
			this.$api({
			    method: 'get',
			    url: 'api/province'
			}).then(res=>{
				this.area_leave='省';
				this.area_list=res.data;
			});
		},
		choose_area:function(e){
			let code=e.target.getAttribute('code');
			let text=e.target.getAttribute('text');
			if(this.area_leave=='省'){
				this.set_city_data(code);
				this.pro_text=text
				this.pro_code=code
			}
			if(this.area_leave=='市'){
				this.city_text=text
				this.city_code=code
				this.set_area_data(code);
			}
			if(this.area_leave=='区'){
				this.area_text=text
				this.area_code=code
				this.show_select_area_box=false;
			}
			this.area_str=this.pro_text+'/'+this.city_text+'/'+this.area_text
		},
		set_city_data:function(code){
			 this.$api({
			    method: 'get',
			    url: 'api/city',
				data:{q:code}
			}).then(res=>{
				this.area_list=res.data;
				this.area_leave='市';
			});
		},
		set_area_data:function(code){
			 this.$api({
			    method: 'get',
			    url: 'api/area',
				data:{q:code}
			}).then(res=>{
				this.area_list=res.data;
				this.area_leave='区';
			});
		},
		getFileDate() {
		  let date = new Date();
		  return date.getFullYear() + '_' + (date.getMonth() + 1) + '_' + date.getDate() + '_' + date.getTime()
		},
		choose_video_field:function(e){
			this.video_field_name=e.target.getAttribute('video_field_name');
			console.log(this.video_field_name);
		},
		selectVideo1(file, fileList) {
		  this.upload(file,'environment_url'); 
		},
		selectVideo2(file, fileList) {
		  this.upload(file,'outside_url'); 
		},
		selectVideo3(file, fileList) {
		  this.upload(file,'act_url'); 
		},
		selectVideo4(file, fileList) {
		  this.upload(file,'inhouse_url'); 
		},
        
		async upload(file,video_field_name) {
		  let loadingService = this.$loading(this.loading);
		  let pahtName = 'organization/organization_id_'+this.organization_id;
		  let bucketName = 'servicetuoyusaas';
		  if (file.raw) {
		      file = file.raw;
		      file.file_name = file.name;
		      let fileName = pahtName + '/' + this.getFileDate() + '_' + file.file_name;
		      let res = await obs.upload(file, fileName, bucketName);
		      if (res == 'success') {
		        this[video_field_name]=fileName
		        console.log( this[video_field_name]);
				 loadingService.close();
		      }
		    } 
		
		},
    },
};
</script>
<style lang="scss">
	.top_tit{ display: flex; justify-content: space-between; align-items: center;}
	.logut_btn{ background: rgba(0,0,0,.3) url(~@/assets/index/icon_logout.png) no-repeat 0.35rem center;
	 padding-left: 1.05rem; border-radius: 0.2rem; font-size: 1rem; height: 1.8rem; line-height: 1.8rem; width: 4rem; background-size:1rem 1rem; color:#fff; margin-right:1rem;}
	
.el-select-dropdown__item {
    width: 100%;
}
.video_bg {
    background: url('../../assets/index/video_bg.png') no-repeat center;
    background-size: 100% 100%;
  }
.el-checkbox{ margin-right: 0.8rem;}
.swiper-wrapper video{ width: 100%;}
.video_item{width: 6.625rem; height: 6.625rem; position: relative; border:1px solid #ccc; border-radius: 0.375rem;}
.video_item .video-wrap{ width: 100%; height: 100%;}
.video_item video{width:100%; height: 100%;}
.video_item img{width:3rem; height: 3rem; position: absolute;left: 50%; top:50%; margin-left: -1.5rem; margin-top:-1.5rem;}
.video_item .upload-del{width:2rem; height: 2rem; position: absolute;left:100%; margin-left:-1rem; margin-top:-0.5rem;top:0;}
.refine-box__class_type_arr{ padding:0.6rem 0;}
.select_area_box{
	    width: 100%;
	    background: #fff;
	    height: 20rem;
	    position: fixed;
	    z-index: 999;
	    bottom: 0;
		
}
.select_area_box .tit{
	height: 2rem;
	background: #ddd;
	color: #333;
	line-height: 2rem;
	text-align: left;
	padding:0 1rem;
}
.select_area_box .area_list{
	overflow: auto;
	height: 18rem;
	line-height: 2rem;
}
.address-container{ height: 100%;}
.city_select_box{   
	width: 100%;
    height: 20rem;
    position: fixed;
    bottom: 0px;
    z-index: 999;
	}
	.mask{ 
	width: 100%;
    height: 100%;
    position: fixed;
    bottom: 0px;
	background: rgba(0,0,0,.5);
    z-index: 990;}
.container {
    height: 100%;

    .top-box {
        position: fixed;
        z-index: 2;
        top: 0;
        width: 100%;
        height: 125px;
        // background-image: url('/img/main/background.png');
        // background-size: 100% 125px;

        .background {
            position: absolute;
            left: 0;
            z-index: -1;
            width: 100%;
            height: 125px;
        }

        .top {
            margin: 25px 0 0 15px;
            text-align: left;
            font-size: 18px;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 27px;
        }

        .bottom {
            z-index: 99;
            margin: 4px 12px 0 15px;
            display: flex;
            align-items: center;

            .phone {
                margin-right: auto;
                font-size: 14px;
                font-weight: 400;
                color: #FFF;
                line-height: 20px;
            }

            .guide {
                font-size: 13px;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 20px;
                background-clip: text;
            }

            .icon {
                margin-left: 3px;
                width: 13px;
                height: 13px;
            }
        }
    }

    .refine-box {
        position: absolute;
        top: 100px;
        z-index: 99;
        height: calc(100% - 100px);
        width: 100%;
        overflow-y: scroll;
        background: #F7F8FA;
        border-radius: 10px 10px 0px 0px;
        padding: 0 15px 150px 15px;

        .refine-box__title {
            margin-top: 15px;
            display: flex;
            text-align: left;
            align-items: center;
            font-size: 15px;
            font-weight: 500;
            color: #333333;
            line-height: 24px;
        }

        .refine-box__subtitle {
            text-align: left;
            font-size: 12px;
            font-weight: 400;
            color: #888888;
            line-height: 18px;
        }
		.refine-box__city {
           margin-top: 15px;
           width:100%;
           height: 3rem;
		   line-height: 3rem;
		   border-radius: 0.325rem;
		   background: #fff;
		   text-align: left;
		   padding:0 0.9375rem;
        }
		.refine-box__select {
		    margin-top: 15px;
		    width:100%;
		    .el-input__inner {
		        background: #ffffff;
		    }
		}
        .refine-box__input {
            margin-top: 15px;

            .el-input__inner {
                background: #ffffff;
            }
        }

        .el-upload--picture-card,
        .el-upload-list--picture-card .el-upload-list__item {
            flex: 0 0 106px;
            width: 106px;
            height: 106px;
            display: flex;
            margin-right: 12px;
            align-items: center;
            justify-content: center;
            border-width: 1px;
        }

    }

    .forget-btn-box {
        z-index: 100;
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 111px;
        background: #FFFFFF;
        box-shadow: 0px -1px 4px 0px rgba(242, 242, 242, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;

        .login-btn {
            width: 323px;
            font-size: 19px;
            color: #ffffff;
        }

        .footer {
            bottom: 10px;
        }
    }

}
</style>